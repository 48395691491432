<template>
	<v-app style=" background-image: linear-gradient(to left top, rgba(238, 238, 238, 0.3), rgba(255, 255, 255, 0.7));">
		<v-app-bar
			id="app-bar"
			app
			:prominent="$vuetify.breakpoint.xs"
			absolute
			elevation="0"
			color="transparent"
		>
			<v-row align="center" class="mt-2">
				<v-col cols="6" sm="4" order-sm="1" align="center">
					<v-card flat width="167">
						<a href="https://globaldesk.com.bo/" target="_blank">
							<v-img 
								max-height="50"
								contain
								:src="require('@/assets/logo_globaldesk.jpg')"
							></v-img>
						</a>
					</v-card>
				</v-col>
				<v-col cols="6" sm="4" order-sm="3" align="center">
					<v-card flat width="100">
						<a href="https://gavcom.globaldesk.com.bo/home" target="_blank">
							<v-img
								width="100"
								:src="require('@/assets/logo_gavcom_100_60.png')"
							>
							</v-img>
						</a>
					</v-card>
				</v-col>
				<v-col cols="12" sm="4" order-sm="2" align="center">
					<div class="text-center uppercase title font-weight-bold">Registro de clientes</div>
				</v-col>
			</v-row>
		</v-app-bar>

		<v-content>
			<v-container v-if="showFormContent" fluid class="mt-2 mt-sm-8">
				<v-row justify="center" align="center" no-gutters>
					<v-col cols="12" sm="8" md="6" lg="5" xl="4">
						<template v-if="showErrorForm">
							<v-card
								flat
								class="mb-2"
								height="auto"
							>
								<v-alert
									text
									elevation="2"	
									color="red"
									transition="scale-transition"
								>
									<v-icon color="red" left>mdi-alert-circle</v-icon>
									<strong>Acceso denegado: </strong> Lo sentimos, pero no puede acceder al formulario de registro de clientes.
								</v-alert>
							</v-card>
						</template>
						<template v-else>
							<v-img :src="editedItem.companyLogo"
								aspect-ratio="1.5" 
								max-height="150"
								contain
							/>

							<div class="text-center uppercase title font-weight-bold py-2">{{editedItem.companyName}}</div>

							<p class="text-justify">Bienvenido al formulario de registro, por favor siga los siguientes pasos para afiliarse.</p>

							<v-stepper
								v-model="step"
								vertical
							>
								<v-stepper-step
									:complete="step > 1"
									:color="step > 1 ? 'success' : 'info darken-1'"
									step="1"
								>
									Registro
								</v-stepper-step>

								<v-stepper-content step="1">
									<v-card
										flat
										class="mb-2"
										height="auto"
									>
										<p class="text-justify">¿Aún no tienes una cuenta <strong>Global Desk</strong>? <a href="https://globaldesk.com.bo/people/create-account" target="_blank" class="info--text">Crear Cuenta</a></p>
										<base-btn block color="primary" @click="step = 2" icon="mdi-emoticon-happy-outline" btnText="¡Ya tengo cuenta!"></base-btn>
									</v-card>
								</v-stepper-content>

								<v-stepper-step
									:complete="step > 2"
									:color="step > 2 ? 'success' : 'info darken-1'"
									step="2"
								>
									Afiliación
								</v-stepper-step>

								<v-stepper-content step="2">
									<v-card
										flat
										class="mb-2"
										height="auto"
									>
										<v-form ref="CustomerForm" onSubmit="return false;">
											<p class="text-justify mb-1">Ingrese su código <strong>Global Desk</strong>.
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<v-icon class="mb-1"
															color="info darken-1"
															dark
															size="20"
															v-bind="attrs"
															v-on="on"
														>
															mdi-help-circle-outline
														</v-icon>
													</template>
													<span>Es el código de identificación personal de su cuenta <strong>Global Desk</strong>.</span>
												</v-tooltip>
											</p>
											<base-text-field v-model="editedItem.gdpiCode" label="Código Global Desk" class="mb-2" counter maxlength="30" 
											prepend-icon="mdi-shield-account"
											:rules="[commonValRules.required, validationRules.gdpiCodeRules.counter]" required clearable></base-text-field>
											<base-btn block color="primary" @click="enrollment()" icon="mdi-check" btnText="Afiliarme"></base-btn>
										</v-form>
									</v-card>
								</v-stepper-content>

								<v-stepper-step
									:complete="step == 3"
									color="success"
									step="3"
								>
									Finalización
								</v-stepper-step>

								<v-stepper-content step="3" class="ml-0">
									<v-card
										flat
										class="mb-2"
									>
										<h3 class="text-center mb-2">¡Felicidades {{editedItem.customerName}}!</h3>
										<p class="text-justify">Ahora ya eres cliente de <b>{{editedItem.companyName}}</b> y ya puedes disfrutar de los distintos productos y servicios que <b>{{editedItem.companyName}}</b> tiene para ti.</p>
										<base-btn v-if="editedItem.hasVirtualStore" block color="primary" icon="mdi-storefront" btnText="Ir a la tienda" :href="editedItem.virtualStoreLink" target="_self"></base-btn>
										<p class="text-center mt-6">¡Estamos muy contentos de seguir conectando a las personas a través de nuestros servicios!</p>
									</v-card>
								</v-stepper-content>
							</v-stepper>
						</template>
					</v-col>
				</v-row>
			</v-container>

			<base-loader />
		</v-content>
	</v-app>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import PersonData from '@/mixins/person-data'

export default {
	mixins: [MainExternalData, PersonData],

	data() {
		return {
			showFormContent: false,
			step: 1,
			editedItem: {
        companyId: '',
        companyName: '',
				companyLogo: '',

				gdpiCode: '',
				customerName:'',

				hasVirtualStore: false,
				virtualStoreLink: ''
      },
			showErrorForm: false,
			validationRules: {
        gdpiCodeRules: {
          counter: value => (!!value && value.length <= 30) || 'No debe tener más de 30 caracteres.',
				}
      }
		}
	},

	async created() {
		// console.log('CustomerForm')
		await this.verifyToken();
		this.showFormContent = true;
	},

	methods: {
		async verifyToken() {
			let me=this;

			if (!me.$route.params.token) {
				me.showErrorForm = true;
				return;
			}

			try {
        me.SHOW_LOADING()
				let request={
					'token': me.$route.params.token,
				};
				await me.getObjectResponseWithoutHeaders('api/CustomerLink/VerifyCompanyToken', request).then(data => {
					if (data === undefined) {
						me.showErrorForm = true;
						return;
					}

					me.editedItem.companyId = data.companyId;
					me.editedItem.companyName = data.visualName;
					me.editedItem.companyLogo = data.logo;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async enrollment(){
      let me=this;
      if (!me.$refs.CustomerForm.validate()) {
        return;
      }

      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        let request={
					'token': me.$route.params.token,
          'companyId': me.editedItem.companyId,
          'gdpi': me.editedItem.gdpiCode,
        };
        me.formLoading=true;
        await me.getObjectResponseWithoutHeaders('api/CustomerLink/Enrollment', request).then(data => {
          if (data === undefined) {
            return;
          }
					
					me.editedItem.customerName = data.customerName;
					me.editedItem.hasVirtualStore = data.hasVirtualStore;
					me.editedItem.virtualStoreLink = data.virtualStoreLink;
					me.step = 3;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },
	},
}
</script>